import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/(marketing)": [4,[2]],
		"/(marketing)/changelog": [5,[2]],
		"/(marketing)/dpa": [6,[2]],
		"/(marketing)/privacy": [7,[2]],
		"/(marketing)/terms": [8,[2]],
		"/waitlist": [9,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';